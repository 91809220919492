import React from "react";
// import { Grid } from "@mui/material";
// import { NoData } from "components/layouts";
// import { Loader } from "components/Utilities";
// import { defaultPageInfo } from "helpers/mockData";
// import { useLazyQuery, useQuery } from "@apollo/client";
// import { getEnrolles } from "components/graphQL/useQuery";
// import { hmoDashboard } from "components/graphQL/useQuery";
// import EnrolleesTable from "components/tables/EnrolleesTable";
// import HmoDashboardChart from "components/layouts/HmoDashboardChart";
import { Info } from "@mui/icons-material";

const HmoDashboard = () => {
  // const [hmoEnrollees, setHmoEnrollees] = useState([]);
  // const providerId = localStorage.getItem("partnerProviderId");
  // const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  // const { data, error, loading } = useQuery(hmoDashboard, {
  //   variables: {
  //     providerId: providerId,
  //   },
  // });

  // const [
  //   fetchEnrollees,
  //   {
  //     error: enrolleeError,
  //     loading: enrolleeLoading,
  //     refetch,
  //     variables,
  //     networkStatus,
  //   },
  // ] = useLazyQuery(getEnrolles, {
  //   notifyOnNetworkStatusChange: true,
  //   variables: { providerId: providerId },
  // });

  // const enrolleesParams = {
  //   enrollees: hmoEnrollees,
  //   setEnrollees: setHmoEnrollees,
  //   pageInfo,
  //   setPageInfo,
  //   fetchEnrollees,
  //   error: enrolleeError,
  //   loading: enrolleeLoading,
  //   refetch,
  //   variables,
  //   networkStatus,
  // };

  // const fetchData = (fetch) => {
  //   fetch({
  //     variables: {
  //       providerId: providerId,
  //       first: 10,
  //     },
  //   })
  //     .then(({ data }) => {
  //       setHmoEnrollees(data?.getEnrollees?.data || []);
  //       setPageInfo(data?.getEnrollees?.pageInfo || {});
  //     })
  //     .catch((error) => {
  //       // eslint-disable-next-line no-console
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchData(fetchEnrollees);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fetchEnrollees, providerId]);

  // if (loading) return <Loader />;
  // if (error) return <NoData error={error} />;

  return (
    // <Grid container direction="column" rowGap={3}>
    //   <HmoDashboardChart data={data?.getStats} />
    //   <EnrolleesTable showHeader={true} enrolleesParams={enrolleesParams} />
    // </Grid>
    <TempDashboardPage />
  );
};

export const TempDashboardPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Info sx={{ fontSize: 32, marginRight: "0.5rem" }} />
        <p>Dashboard page is currently under maintenance</p>
      </div>
    </div>
  );
};

export default HmoDashboard;

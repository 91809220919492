import * as React from "react";
import { SvgIcon } from "@mui/material";
const Shield = (props) => (
  <SvgIcon
    width={18}
    height={20}
    viewBox="0 0 20 22"
    {...props}
  >
    <path
      d="M1 9.417C1 6.219 1 4.62 1.378 4.082C1.755 3.545 3.258 3.03 6.265 2.001L6.838 1.805C8.405 1.268 9.188 1 10 1C10.812 1 11.595 1.268 13.162 1.805L13.735 2.001C16.742 3.03 18.245 3.545 18.622 4.082C19 4.62 19 6.22 19 9.417V10.991C19 16.629 14.761 19.366 12.101 20.527C11.38 20.842 11.02 21 10 21C8.98 21 8.62 20.842 7.899 20.527C5.239 19.365 1 16.63 1 10.991V9.417Z"
      // stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M13 11H10M10 11H7M10 11V8M10 11V14"
      // stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </SvgIcon>
);
export default Shield;

import React from "react";
// import { Grid } from "@mui/material";
// import DashboardCharts from "components/layouts/DashboardChart";
import { TempDashboardPage } from "./hmoPages/HmoDashboard";

const Dashboard = () => {
  return (
    // <Grid container>
    //   <DashboardCharts />
    // </Grid>
    <TempDashboardPage />
  );
};

export default Dashboard;

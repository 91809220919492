import { gql } from "@apollo/client";
export const updateDrugOrder = gql`
  mutation updateDrugOrder($id: String!) {
    updateDrugOrder(data: { id: $id, status: "processing" }) {
      drugOrder {
        _id
      }
    }
  }
`;
export const validateEnrollee = gql`
  mutation validateEnrollee($hmoId: String!, $providerId: String!) {
    validateEnrollee(data: { hmoId: $hmoId, providerId: $providerId }) {
      enrollee {
        _id
        firstName
        lastName
        hmoId
        expiryDate
        plan
        phone
        photo
      }
    }
  }
`;

export const Login_USER = gql`
  mutation Login($data: LoginUserInput!) {
    login(data: $data) {
      message
      account {
        _id
        dociId
        access_token
        refresh_token
        role {
          _id
          name
          description
        }
        providerId
        userTypeId {
          _id
          name
        }
        email
      }
    }
  }
`;
export const CREATE_MESSAGE = gql`
  mutation createMessage(
    $recipient: String!
    $sender: String!
    $subject: String!
    $body: String!
  ) {
    createMessage(
      data: {
        recipient: $recipient
        sender: $sender
        subject: $subject
        body: $body
      }
    ) {
      messages {
        _id
        recipient
        subject
        sender
        createdAt
        updatedAt
        body
      }
    }
  }
`;
export const DELETE_PERMISSION = gql`
  mutation deletePermission($id: String!) {
    deletePermission(data: { id: $id }) {
      count
      message
    }
  }
`;
export const DELETE_PLAN = gql`
  mutation deletePlan($id: String!) {
    deletePlan(data: { id: $id })
  }
`;
export const UPDATE_PLAN = gql`
  mutation updatePlan(
    $id: String!
    $name: String
    $amount: Float
    $provider: ID
    $description: String
  ) {
    updatePlan(
      data: {
        id: $id
        name: $name
        amount: $amount
        provider: $provider
        description: $description
      }
    ) {
      _id
      name
      amount
      description
      createdAt
      updatedAt
    }
  }
`;
export const createDoctorAccount = gql`
  mutation createDoctorAccount(
    $firstName: String!
    $lastName: String!
    $gender: String
    $phoneNumber: String!
    $hospital: String!
    $specialization: String!
    $email: String!
    $cadre: String!
    $providerId: String!
    $idCard: String!
  ) {
    createDoctorAccount(
      data: {
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        phoneNumber: $phoneNumber
        hospital: $hospital
        email: $email
        specialization: $specialization
        cadre: $cadre
        providerId: $providerId
        idCard: $idCard
      }
    ) {
      profile {
        _id
        dociId
        createdAt
        updatedAt
        firstName
        lastName
        gender
        phoneNumber
        email
        hospital
        specialization
        cadre
        picture
        providerId {
          _id
          name
        }
      }
    }
  }
`;
export const deleteAppointment = gql`
  mutation deleteAppointment($id: String!) {
    deleteAppointment(data: { id: $id }) {
      count
      message
    }
  }
`;
export const deleteProfile = gql`
  mutation deleteProfile($id: String!) {
    deleteProfile(data: { id: $id }) {
      count
    }
  }
`;
export const deleteRole = gql`
  mutation deleteRole($id: String!) {
    deleteRole(data: { id: $id }) {
      count
      message
    }
  }
`;

export const deleteDoctor = gql`
  mutation deleteDoctorProfile($id: String!) {
    deleteDoctorProfile(data: { id: $id }) {
      count
    }
  }
`;
export const cancelDiagnosticTest = gql`
  mutation cancelDiagnosticTest($id: String!, $reason: String!) {
    cancelDiagnosticTest(data: { id: $id, reason: $reason }) {
      diagnosticTest {
        _id
      }
    }
  }
`;
export const completeDiagnosticTest = gql`
  mutation completeDiagnosticTest(
    $id: String!
    $testResults: [TestResultsInput!]!
  ) {
    completeDiagnosticTest(data: { id: $id, testResults: $testResults }) {
      diagnosticTest {
        _id
      }
    }
  }
`;
export const scheduleDiagnosticTest = gql`
  mutation scheduleDiagnosticTest($id: String!, $time: String!) {
    scheduleDiagnosticTest(data: { id: $id, time: $time }) {
      diagnosticTest {
        _id
      }
    }
  }
`;
export const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;

export const updatePartner = gql`
  mutation updatePartnerProfile(
    $id: String!
    $name: String
    $category: String
    $email: String
    $logoImageUrl: String
  ) {
    updatePartnerProfile(
      data: {
        id: $id
        name: $name
        category: $category
        email: $email
        logoImageUrl: $logoImageUrl
      }
    ) {
      partner {
        _id
        name
        email
        category
        logoImageUrl
        accountId
        dociId
      }
    }
  }
`;
export const fulfillDrugOrder = gql`
  mutation fulfillDrugOrder($id: String!) {
    fulfillDrugOrder(data: { id: $id }) {
      drugOrder {
        _id
      }
    }
  }
`;
export const signup = gql`
  mutation signup(
    $authType: String!
    $email: EmailAddress!
    $password: String!
    $role: String
  ) {
    signup(
      data: {
        authType: $authType
        email: $email
        password: $password
        role: $role
      }
    ) {
      account {
        _id
        email
      }
      message
    }
  }
`;
export const updateAppointment = gql`
  mutation updateAppointment(
    $id: String!
    $doctor: String!
    $patient: String
    $date: String!
    $time: String
  ) {
    updateAppointment(
      data: {
        id: $id
        doctor: $doctor
        patient: $patient
        date: $date
        time: $time
      }
    ) {
      appointment {
        _id
        doctor
        patient
        date
        time
        createdAt
        updatedAt
      }
    }
  }
`;
export const verifyHCP = gql`
  mutation verifyHCP($id: String) {
    verifyHCP(data: { id: $id }) {
      _id
      qualification
      license
      yearbook
      alumni_association
      reference
      external_reference
      status
      createdAt
      updatedAt
      profileId
    }
  }
`;
export const requestReferral = gql`
  mutation requestReferral(
    $doctor: String!
    $patient: String!
    $type: String!
    $reason: String!
    $note: String!
    $specialization: String!
  ) {
    requestReferral(
      data: {
        doctor: $doctor
        patient: $patient
        type: $type
        reason: $reason
        note: $note
        specialization: $specialization
      }
    ) {
      referral {
        _id
        doctor
        patient
        type
        reason
        note
        specialization
        testType
        createdAt
        updatedAt
      }
    }
  }
`;
export const addRole = gql`
  mutation createRole(
    $name: String!
    $editable: Boolean
    $description: String
    $permissions: [String!]
  ) {
    createRole(
      data: {
        name: $name
        editable: $editable
        description: $description
        permissions: $permissions
      }
    ) {
      role {
        _id
        name
        permissions
        editable
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const CREATE_PLAN = gql`
  mutation createPlan(
    $name: String!
    $amount: Float!
    $description: String
    $duration: String
    $provider: ID
  ) {
    createPlan(
      data: {
        name: $name
        amount: $amount
        description: $description
        duration: $duration
        provider: $provider
      }
    ) {
      _id
      name
      amount
      description
      createdAt
      updatedAt
    }
  }
`;

export const createEnrollee = gql`
  mutation createEnrollee(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $photo: String
    $hmoId: String!
    $noc: Int
    $plan: String!
    $expiryDate: DateTime!
    $providerId: String!
    $planId: String
    $companyId: String
  ) {
    createEnrollee(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        hmoId: $hmoId
        noc: $noc
        phone: $phone
        photo: $photo
        plan: $plan
        expiryDate: $expiryDate
        providerId: $providerId
        planId: $planId
        companyId: $companyId
      }
    ) {
      enrollee {
        _id
        firstName
        lastName
        hmoId
        phone
        providerId
      }
    }
  }
`;

export const updateEnrollee = gql`
  mutation updateEnrollee(
    $id: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $photo: String
    $hmoId: String!
    $plan: String
    $expiryDate: DateTime!
    $providerId: String!
    $companyId: String
  ) {
    updateEnrollee(
      data: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        hmoId: $hmoId
        phone: $phone
        photo: $photo
        plan: $plan
        expiryDate: $expiryDate
        providerId: $providerId
        companyId: $companyId
      }
    ) {
      enrollee {
        _id
        firstName
        lastName
        hmoId
        phone
      }
    }
  }
`;

export const deleteEnrollee = gql`
  mutation deleteEnrollee($id: String) {
    deleteEnrollee(data: { id: $id }) {
      count
    }
  }
`;

export const uploadEnrollees = gql`
  mutation uploadEnrollees(
    $fileUrl: String!
    $providerId: String!
    $planId: String!
    $replace: Boolean!
    $companyId: String
  ) {
    uploadEnrollees(
      data: {
        fileUrl: $fileUrl
        providerId: $providerId
        bucket: "heala-media"
        planId: $planId
        replace: $replace
        companyId: $companyId
      }
    ) {
      result {
        fileUrl
        totalInserted
        bucket
      }
    }
  }
`;

export const addEditDelHMOPlans = gql`
  mutation updateProvider($id: String!, $plans: [HmoPlansInput!]) {
    updateProvider(data: { id: $id, hmoPlans: $plans }) {
      provider {
        _id
        name
        hmoPlans {
          name
          planId
        }
      }
    }
  }
`;

export const updatePartnerProfile = gql`
  mutation updatePartnerProfile(
    $profileId: String!
    $partnerName: String
    $phone: String
    $address: String
    $logoImageUrl: String
    $bankName: String!
    $accName: String!
    $accNumber: String!
  ) {
    updatePartnerProfile(
      data: {
        id: $profileId
        name: $partnerName
        phone: $phone
        address: $address
        logoImageUrl: $logoImageUrl
        bankDetails: {
          name: $bankName
          accName: $accName
          accNumber: $accNumber
        }
      }
    ) {
      partner {
        _id
        name
        email
        phone
        address
        bankDetails {
          name
          accNumber
          nuban
        }
        category
        logoImageUrl
        profileUrl
        dociId
      }
    }
  }
`;

export const updateEmail = gql`
  mutation updateEmail($data: UpdateEmailInput) {
    updateEmail(data: $data) {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
      }
    }
  }
`;

export const updatePassword = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(
      data: { password: $currentPassword, newPassword: $newPassword }
    ) {
      _id
      role {
        _id
        name
      }
      email
      dociId
      createdAt
      updatedAt
      isEmailVerified
      isPasswordTemporary
    }
  }
`;

export const updateProfileEmail = gql`
  mutation updatePartnerProfile($id: String, $email: String) {
    updatePartnerProfile(data: { id: $id, email: $email }) {
      partner {
        _id
        accountId
      }
    }
  }
`;

export const generateAccountAPIKey = gql`
  mutation generateAccountApiKey {
    generateAccountApiKey {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
        isActive
        apiKey
        providerId
      }
      message
    }
  }
`;

export const updateWidgetConfiguration = gql`
  mutation updatePartnerConfiguration(
    $partnerId: String!
    $widgetColor: String
    $widgetPosition: String
    $widgetSize: String
    $widgetTextHeader: String
    $widgetTextColor: String
    $widgetLogo: String
    $widgetIcon: String
    $category: String
  ) {
    updatePartnerConfiguration(
      data: {
        partner: $partnerId
        widgetColor: $widgetColor
        widgetPosition: $widgetPosition
        widgetSize: $widgetSize
        widgetTextHeader: $widgetTextHeader
        widgetTextColor: $widgetTextColor
        widgetLogo: $widgetLogo
        widgetIcon: $widgetIcon
        category: $category
      }
    ) {
      partner
      widgetColor
      widgetPosition
      widgetSize
      widgetTextHeader
      widgetTextColor
      widgetLogo
      widgetIcon
      category
    }
  }
`;

export const updatePartnerSubdomain = gql`
  mutation updatePartnerSubdomain($partnerId: String!, $subdomain: String!) {
    updatePartnerSubdomain(
      data: { partner: $partnerId, subdomain: $subdomain }
    ) {
      subdomain
    }
  }
`;

export const addCompany = gql`
  mutation createCompany(
    $name: String!
    $email: String
    $providerId: String!
    $phone: String
    $logo: String
    $address: String
  ) {
    createCompany(
      data: {
        name: $name
        email: $email
        providerId: $providerId
        phone: $phone
        address: $address
        logo: $logo
      }
    ) {
      company {
        name
      }
    }
  }
`;

export const updateCompany = gql`
  mutation updateCompany(
    $name: String!
    $email: String
    $companyId: String!
    $phone: String
    $logo: String
    $address: String
    $providerId: String
  ) {
    updateCompany(
      data: {
        id: $companyId
        name: $name
        email: $email
        phone: $phone
        address: $address
        logo: $logo
        providerId: $providerId
      }
    ) {
      company {
        name
        email
        address
      }
    }
  }
`;

export const deleteCompany = gql`
  mutation deleteCompany($companyId: String!) {
    deleteCompany(data: { id: $companyId })
  }
`;

export const deactivateEnrollee = gql`
  mutation deactivateEnrollee($enrolleeId: String!) {
    deactivateEnrollee(data: { id: $enrolleeId }) {
      enrollee {
        _id
        deactivated
      }
    }
  }
`;

export const resetPassword = gql`
  mutation resetPassword($email: String!) {
    resetPassword(data: { email: $email })
  }
`;

export const completePasswordReset = gql`
  mutation completePasswordReset(
    $email: String!
    $password: String!
    $otp: String!
  ) {
    completePasswordReset(
      data: { email: $email, password: $password, otp: $otp }
    ) {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
      }
    }
  }
`;

// export const DELETE_PLAN = gql`
//   mutation deletePlan($id: String!) {
//     deletePlan(data: { id: $id }) {
//       count
//       message
//     }
//   }
// `
// export const UPDATE_PLAN = gql`
//   mutation updatePlan(
//     $id: String
//     $name: String
//     $amount: Float
//     $provider: String
//     $description: String
//   ) {
//     updatePlan(
//       data: {
//         id: $id
//         name: $name
//         amount: $amount
//         provider: $provider
//         description: $description
//       }
//     ) {
//       plan {
//         _id
//         name
//         amount
//         description
//         createdAt
//         updatedAt
//       }
//     }
//   }
// `

export const updatePrescriptionReferralMutation = gql`
  mutation updatePrescriptionReferral(
    $referralId: String!
    $drugsPartner: String
    $drugs: [String!]
    $testsPartner: String
    $tests: [String!]
  ) {
    updatePrescriptionReferral(
      data: {
        referralId: $referralId
        status: "approved"
        drugsPartner: $drugsPartner
        drugs: $drugs
        testsPartner: $testsPartner
        tests: $tests
      }
    ) {
      _id
      status
    }
  }
`;

export const changeTestApprovalStatus = gql`
  mutation changeTestApprovalStatus($id: String!, $status: String!) {
    changeTestApprovalStatus(
      data: { id: $id, status: $status }
    ) {
      _id
      status
  }
}
`;

export const changeDrugApprovalStatus = gql`
  mutation changeDrugApprovalStatus($id: String!, $status: String!) {
    changeDrugApprovalStatus(data: { id: $id, status: $status }) {
      _id
      status
    }
  }
`;
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { makeStyles } from "@mui/styles";
import { useAlert } from "components/hooks";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { CustomButton } from "components/Utilities";
import MainModal from "components/modals/MainModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { resetPassword } from "components/graphQL/Mutation";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FormikControl, LoginInput } from "components/validation";
import { Grid, Typography, Checkbox, InputAdornment } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  form: theme.mixins.toolbar,
  btn: {
    "&.MuiButton-root": {
      height: "5rem",
      background:
        "linear-gradient(130deg, rgb(62, 94, 169) 0%, rgb(62, 94, 169) 34%, rgb(126, 237, 186) 100%)",
      width: "100%",
      borderRadius: "3rem",
      fontSize: "1.3rem",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: "400",
    },
  },
  header: {
    "&.MuiGrid-root": {
      fontSize: "2rem",
      lineHeight: "2.6rem",
      color: "#010101",
    },
  },
  checkbox: {
    "&.MuiCheckbox-root": {
      padding: "0 !important",
    },
  },
}));

const LoginForm = ({ onSubmit }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [reset] = useMutation(resetPassword);
  const [open, setOpen] = useState(false);
  const { displayAlert, getErrorMsg } = useAlert();
  const [showPassword, setShowPassword] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(() => {
    // Check local storage for lock state
    const lockState = localStorage.getItem('isLocked');
    if (lockState) {
      const { isLocked, timestamp } = JSON.parse(lockState);
      const now = Date.now();
      if (isLocked && now < timestamp) {
        // Account is still locked
        return true;
      } else {
        // Lock period has expired
        localStorage.removeItem('isLocked');
        return false;
      }
    }
    return false;
  });

  useEffect(() => {
    let timer;
    if (isLocked) {
      timer = setTimeout(() => {
        setIsLocked(false);
        setFailedAttempts(0);
        localStorage.removeItem('isLocked');
      }, 300000); // 5 minutes
    }
    return () => clearTimeout(timer);
  }, [isLocked]);

  const greenButton = {
    background: theme.palette.common.green,
    hover: theme.palette.common.green,
    active: theme.palette.primary.dark,
  };

  const initialValues = {
    email: "",
    password: "",
    authType: "normal",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string().trim().required("password is required"),
  });

  const forgetPasswordValidationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const onSubmitforgettenPassword = async (values) => {
    const { email } = values;
    try {
      const { data } = await reset({
        variables: {
          email,
        },
      });
      if (data?.resetPassword) {
        displayAlert("success", "Password reset email sent");
        localStorage.setItem("rest_password_email", email);
        localStorage.setItem("request_new_OTP_mail", email);
        localStorage.setItem("resetPasswordAuth", true);
        history.push("/otp");
      }
    } catch (err) {
      console.error(err);
      const errMsg = getErrorMsg(err);
      displayAlert("error", errMsg);
    }
  };

  const handleLogin = async (values, onSubmitProps) => {
    if (isLocked) {
      displayAlert("error", "Account is locked. Please try again later.");
      return;
    }
    try {
      await onSubmit(values, onSubmitProps);
    } catch (error) {
      setFailedAttempts((prev) => prev + 1);
      // const errorMsg = error.message || "Failed to login. Check your credentials and try again.";
      if (failedAttempts + 1 >= 5) {
        setIsLocked(true);
        localStorage.setItem('isLocked', JSON.stringify({ isLocked: true, timestamp: Date.now() + 600000 }));
        displayAlert("error", "Account locked due to multiple failed attempts.");
      } else {
        // displayAlert("error", errorMsg);
      }
    }
  };

  return (
    <Grid>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
        validateOnMount={false}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid, dirty }) => {
          return (
            <Form>
              <Grid container item gap={2}>
                <Grid item container justifyContent="center" rowSpacing={1}>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    md={12}
                    mb={3}
                    sm={10}
                  >
                    <Typography variant="h5" className={classes.header}>
                      LOGIN TO PARTNER ACCOUNT
                    </Typography>
                  </Grid>

                  <Grid item container md={12} sm={10}>
                    <LoginInput
                      label="Email"
                      name="email"
                      type="email"
                      id="email"
                      autoFocus={true}
                      placeholder="Enter your email"
                      hasStartIcon={false}
                    />
                  </Grid>
                  <Grid item container md={12} sm={10}>
                    <LoginInput
                      id="password"
                      label="Password"
                      name="password"
                      autoFocus={false}
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      hasStartIcon={false}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          onClick={() => setShowPassword((prev) => !prev)}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </InputAdornment>
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    md={12}
                    sm={10}
                    alignItems="center"
                    style={{ marginTop: "5%" }}
                  >
                    <Grid
                      item
                      container
                      gap={2}
                      sx={{ flex: 1, alignItems: "center" }}
                    >
                      <Grid item>
                        <Checkbox
                          className={classes.checkbox}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "12px",
                            marginLeft: "-10%",
                            fontWeight: "400",
                          }}
                        >
                          Remember Me
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.common.danger,
                          textDecoration: "none",
                          fontSize: "12px",
                          marginLeft: "-10%",
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpen(true)}
                        className={classes.link}
                      >
                        Forgot Password
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container margin="auto" mt={3} md={12} sm={10}>
                  <CustomButton
                    variant="contained"
                    title="Login"
                    type={greenButton}
                    role="button"
                    borderRadius={20}
                    className={classes.btn}
                    isSubmitting={isSubmitting}
                    disabled={!(dirty || isValid) || isLocked}
                  />
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                ></Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Grid>
        <MainModal
          isOpen={open}
          setIsOpen={setOpen}
          headerText="Forgot Password"
          onClose={() => setOpen(false)}
        >
          <Formik
            initialValues={{ email: "" }}
            validationSchema={forgetPasswordValidationSchema}
            onSubmit={onSubmitforgettenPassword}
          >
            {() => {
              return (
                <Form>
                  <Grid container item gap={2}>
                    <Grid item container justifyContent="center" rowSpacing={1}>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        md={12}
                        mb={3}
                        sm={10}
                      >
                        <Typography variant="h5" className={classes.header}>
                          Forgot Password
                        </Typography>
                      </Grid>

                      <Grid item container md={12} sm={10}>
                        <LoginInput
                          label="Email"
                          name="email"
                          type="email"
                          id="email"
                          autoFocus={true}
                          placeholder="Enter your email"
                          hasStartIcon={false}
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        md={12}
                        sm={10}
                        alignItems="center"
                        style={{ marginTop: "5%" }}
                      >
                        <Grid
                          item
                          container
                          gap={2}
                          sx={{ flex: 1, alignItems: "center" }}
                        >
                          <Grid item>
                            <Checkbox
                              className={classes.checkbox}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: "12px",
                                marginLeft: "-10%",
                                fontWeight: "400",
                              }}
                            >
                              Remember Me
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item container margin="auto" mt={3} md={12} sm={10}>
                        <CustomButton
                          variant="contained"
                          type={greenButton}
                          role="button"
                          borderRadius={20}
                          className={classes.btn}
                          isSubmitting={false}
                          disabled={false}
                          title="Reset Password"
                          width="100%"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </MainModal>
      </Grid>
    </Grid>
  );
};

export default LoginForm;

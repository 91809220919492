import React from "react";
// import { Grid } from "@mui/material";
// import { DashCharts } from "components/layouts";
import { TempDashboardPage } from "./hmoPages/HmoDashboard";

const Dashboards = () => {
  return (
    // <Grid container direction="column">
    //   <DashCharts />
    // </Grid>
    <TempDashboardPage />
  );
};

export default Dashboards;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import OtpInput from "react-otp-input";
import vec from "assets/images/vec.png";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import LoginInput from "components/validation/LoginInput";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Typography, InputAdornment } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  completePasswordReset,
  resetPassword,
} from "components/graphQL/Mutation";
import { useAlert } from "components/hooks";
import { CustomButton, Loader } from "components/Utilities";

export const pageOneUseStyles = makeStyles((theme) => ({
  form: theme.mixins.toolbar,
  background: {
    width: "100%",
    minHeight: "100vh !important",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(98.44deg, #3e5ea9 1.92%, #7eedba 122.04%) !important",
  },
  secV: {
    backgroundImage: `url(${vec})`,
    opacity: " 0.05",
    width: "100%",
    height: "100.1%",
    position: "absolute",
    backgroundRepeat: "round",
    zIndex: 0,
  },
  btn: {
    "&.MuiButton-root": {
      //... theme.typography.btn,
      height: "5rem",
      background:
        "linear-gradient(130deg, rgb(62, 94, 169) 0%, rgb(62, 94, 169) 34%, rgb(126, 237, 186) 100%)",
      width: "100%",
      borderRadius: "3rem",
      fontSize: "1.3rem",
      boxShadow: "none",
      textTransform: "capitalize",
      fontWeight: "400",
    },
  },
  header: {
    "&.MuiTypography-root": {
      fontSize: "2rem",
      lineHeight: "2.6rem",
      color: "#010101",
      fontWeight: 400,
    },
  },
  typo: {
    "&.MuiTypography-root": {
      cursor: "pointer",
      background: "blue",
      "&:hover": {
        color: "green",
      },
    },
  },
}));

const inputStyle = {
  height: "max(45px,5rem)",
  width: "min(50px, calc(70% + 100px))",
};

const style = {
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  margin: 0,
  overflow: "hidden",
  flexWrap: "nowrap",
};

const forgottenDetails = {
  password: "",
  cPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string("Enter your password")
    .trim()
    .required("Password Required")
    .min(8),
  cPassword: Yup.string()
    .trim()
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "password mismatch"),
    })
    .required("Please confirm Password"),
});

const OTP = () => {
  const timeOut = 59;
  const theme = useTheme();
  const history = useHistory();
  const classes = pageOneUseStyles();
  const [otp, setOtp] = useState("");
  const [load, setLoad] = useState(false);
  const [counter, setCounter] = useState(timeOut);
  const { displayAlert, getErrorMsg } = useAlert();
  const [showPassword, setShowPassword] = useState(false);
  const [reset, { loading }] = useMutation(resetPassword);
  const [resetPasswords] = useMutation(completePasswordReset);

  const greenButton = {
    background: theme.palette.common.green,
    hover: theme.palette.common.green,
    active: theme.palette.primary.dark,
  };

  const handleChange = (e) => setOtp(e);

  const requestNewOTP = async () => {
    if (counter === 0) {
      setLoad(true);
      const email = localStorage.getItem("request_new_OTP_mail");
      try {
        const { data } = await reset({
          variables: {
            email,
          },
        });
        if (data?.resetPassword) {
          setLoad(false);
          displayAlert("success", "New OTP has been sent to your email");
        }
      } catch (err) {
        console.error(err);
        const errMsg = getErrorMsg(err);
        displayAlert("error", errMsg);
      }
    }
  };

  const onSubmit = async (values, onSubmitProps) => {
    const emailValue = localStorage.getItem("rest_password_email");
    const { password } = values;
    try {
      const { data } = await resetPasswords({
        variables: {
          password,
          email: emailValue,
          otp,
        },
      });
      if (data) {
        localStorage.removeItem("rest_password_email");
        displayAlert("success", "Password reset successful");
        localStorage.removeItem("resetPasswordAuth");
        history.replace("/");
      }
      setOtp("");
      onSubmitProps.resetForm();
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  useEffect(() => {
    if (counter > 0) {
      const x = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(x);
    }
  }, [counter]);

  return (
    <Grid container  justifyContent="center"
    alignItems="center" sx={{height: "100vh"}} className={classes.background}>
        <div className={classes.secV}></div>
      <Grid
        container
        sx={{ margin: "auto", backgroundColor: "#fff !important",
        borderRadius: "16px",
        padding: "3rem",
        position: "relative",
        zIndex: 50, }}
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
        sm={10}
        xs={10}
        md={6}
        lg={4}
        xl={4}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection={"column"}
          rowSpacing={3}
          sx={{position: "relative", zIndex: 10}}
        >
          <Grid item>
            <Typography gutterBottom color="#000" variant="h6">
              ENTER YOUR OTP
            </Typography>
          </Grid>
          {load && (
            <Grid item>
              <Loader />
            </Grid>
          )}
          <Grid item container justifyContent="center">
            <OtpInput
              value={otp}
              containerStyle={style}
              shouldAutoFocus
              isInputNum
              inputStyle={inputStyle}
              onChange={handleChange}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              separator={
                <span
                  style={{
                    width: "10px",
                    height: "100%",
                    textAlign: "center",
                    fontSize: "min(3vw, 20px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    lineHeight: "50px",
                    // display:""
                  }}
                ></span>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={forgottenDetails}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnMount={false}
            >
              {({ isSubmitting, dirty, isValid }) => {
                return (
                  <Form>
                    <Grid container item rowGap={1}>
                      <Grid item container>
                        <LoginInput
                          id="password"
                          label="New Password"
                          name="password"
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          hasStartIcon={false}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={() => setShowPassword((prev) => !prev)}
                              style={{ cursor: "pointer" }}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item container>
                        <LoginInput
                          id="cPassword"
                          label="Confirm Password"
                          name="cPassword"
                          placeholder="Confirm your password"
                          type={showPassword ? "text" : "password"}
                          hasStartIcon={false}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={() => setShowPassword((prev) => !prev)}
                              style={{ cursor: "pointer" }}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </InputAdornment>
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        alignItems="center"
                        flexWrap="nowrap"
                        justifyContent="space-between"
                      >
                        <div
                          style={{
                            zIndex: "9",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Grid item xs={{ flex: 1 }}>
                            <Typography
                              variant="h5"
                              style={{
                                fontSize: "clamp(1rem, 1.5vw,1.5rem)",
                                color: "#000",
                              }}
                            >
                             
                            </Typography>
                          </Grid>
                          <Grid item justifySelf="flex-end" xs={{ flex: 3 }}>
                            <Typography
                              onClick={requestNewOTP}
                              sx={
                                counter === 0
                                  ? {
                                      textDecoration: "underline",
                                      cursor: "hand",
                                      color: "black",
                                      whiteSpace: "nowrap",
                                      fontSize: "clamp(1rem, 1.5vw,1.5rem)",
                                    }
                                  : ""
                              }
                            >
                              {counter === 0 ? "Resend OTP" : counter}
                            </Typography>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        sm={6}
                        xs={12}
                        marginInline="auto"
                        marginBlock={3}
                      >
                        <CustomButton
                          variant="contained"
                          title="Reset Password"
                          type={greenButton}
                          className={classes.btn}
                          borderRadius={20}
                          isSubmitting={isSubmitting}
                          disabled={!(dirty || isValid)}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OTP;

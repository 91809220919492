import { PageInfo } from "./fragment";
import { gql } from "@apollo/client";
export const getPartner = gql`
  query getPartner($id: String!) {
    getPartner(data: { id: $id }) {
      _id
      name
      email
      phone
      address
      bankDetails {
        name
        accName
        accNumber
        nuban
      }
      category
      logoImageUrl
      profileUrl
      dociId
      providerId
    }
  }
`;
export const getDoctorAvailabilityForDate = gql`
  query getDoctorAvailabilityForDate($doctor: String!, $day: String) {
    getDoctorAvailabilityForDate(doctorId: $doctor, day: $day) {
      day
      available
      times {
        start
        stop
        available
      }
    }
  }
`;
export const getUserTypes = gql`
  ${PageInfo}
  query getUserTypes {
    getUserTypes(filterBy: { name: "HMO Enrollee" }) {
      userType {
        _id
        name
        icon
        description
        providerCount
        createdAt
        updatedAt
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getUserTypeProviders = gql`
  query getUserTypeProviders($id: String) {
    getUserTypeProviders(filterBy: { userTypeId: $id }) {
      provider {
        _id
        name
        icon
        userTypeId {
          _id
          name
        }
        userCount
        doctorCount
        enrolleeCount
        partnerCount
        createdAt
        updatedAt
        userTypeId {
          name
          icon
          createdAt
          updatedAt
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;
export const getDiagnosticDashboard = gql`
  query getDiagnosticDashboard($partner: String!) {
    getDiagnosticDashboard(data: { partner: $partner }) {
      testRequestsCount
      scheduledTestsCount
      completedTestsCount
      cancelledTestsCount
      testRequestsStats {
        year
        month
        sum
      }
      scheduledTestsStats {
        year
        month
        sum
      }
      completedTestsStats {
        year
        month
        sum
      }
      cancelledTestsStats {
        year
        month
        sum
      }
    }
  }
`;
export const getNotifications = gql`
  query getNotifications($user: String) {
    getNotifications(user: $user) {
      data {
        user
        content
        itemId
        ticker
        title
        seen
        tag
        useSound
        role
        saveNotification
        previewImageUri
        previewImageUriThumbnail
        createdAt
        updatedAt
      }
    }
  }
`;
export const getPharmacyDashboard = gql`
  query getPharmacyDashboard($partner: String!) {
    getPharmacyDashboard(data: { partner: $partner }) {
      drugOrderRequestsCount
      completedDrugOrdersCount
      processingDrugOrdersCount
      cancelledDrugOrdersCount
      drugOrderRequestsStats {
        year
        month
        sum
      }
      completedDrugOrdersStats {
        year
        month
        sum
      }
      processingDrugOrdersStats {
        year
        month
        sum
      }
      cancelledDrugOrdersStats {
        year
        month
        sum
      }
    }
  }
`;
export const doctor = gql`
  query doctorProfile($id: String!) {
    doctorProfile(data: { id: $id }) {
      profile {
        _id
        firstName
        lastName
        gender
        phoneNumber
        createdAt
        updatedAt
        email
        hospital
        specialization
        dob
        cadre
        picture
        providerId {
          _id
          name
        }
        status
        dociId
      }
    }
  }
`;
export const getProviders = gql`
  ${PageInfo}
  query getProviders(
    $name: String
    $userTypeId: String
    $page: Int
    $first: Int
  ) {
    getProviders(
      filterBy: { name: $name, userTypeId: $userTypeId }
      page: $page
      orderBy: "-createdAt"
      first: $first
    ) {
      provider {
        _id
        name
        icon
        userTypeId {
          _id
          name
        }
        createdAt
        updatedAt
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
// export const getPatients = gql`

//   ${PageInfo}
//   query findProfiles(
//     $gender: String
//     $page: Int
//     $first: Int
//     $firstName: String
//     $lastName: String
//     $id: String
//     $provider: String
//   ) {
//     profiles(
//       filterBy: {
//         gender: $gender
//         dociId: $id
//         firstName: $firstName
//         lastName: $lastName
//         providerId: $provider
//       }
//       orderBy: "-createdAt"
//       page: $page
//       first: $first
//     ) {
//       data {
//         _id
//         firstName
//         lastName
//         height
//         weight
//         bloodGroup
//         dociId
//         genotype
//         gender
//         phoneNumber
//         provider
//         plan
//         status
//         consultations
//         createdAt
//         image
//       }
//       pageInfo {
//         ...pageDetails
//       }
//     }
//   }
// `;
export const getDrugOrders = gql`
  ${PageInfo}
  query getDrugOrders(
    $page: Int
    $first: Int
    $status: String
    $orderId: String
    $partnerProviderId: String!
  ) {
    getDrugOrders(
      filterBy: {
        status: $status
        partner: $partnerProviderId
        orderId: $orderId
      }
      first: $first
      page: $page
      orderBy: "-updatedAt"
    ) {
      data {
        _id
        partner {
          _id
          name
        }
        patient {
          _id
          firstName
          lastName
          gender
        }
        doctor {
          _id
          firstName
          lastName
          gender
          picture
        }
        orderId
        prescriptionDate
        deliveryFee
        deliveryOption
        consultationId
        note
        cancellationReason
        deliveryFee
        updatedAt
        total
        prescriptions {
          priceListId
          drugName
          drugPrice
          unitPrice
          dosageQuantity
          notes
        }
        userLocation {
          address
          phoneNumber
          state
          lga
          landmark
          lat
          lng
        }
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getPatientsByPlan = gql`
  ${PageInfo}
  query findProfiles($planId: String, $first: Int, $id: String) {
    profilesByPlan(
      filterBy: { planId: $planId }
      orderBy: "-createdAt"
      first: $first
      id: $id
    ) {
      data {
        _id
        firstName
        lastName
        height
        weight
        bloodGroup
        dociId
        genotype
        gender
        phoneNumber
        provider
        plan
        status
        consultations
        createdAt
        image
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getPatientsByStatus = gql`
  ${PageInfo}
  query findProfiles($status: Boolean, $first: Int) {
    profilesByStatus(
      filterBy: { isActive: $status }
      orderBy: "-createdAt"
      first: $first
    ) {
      data {
        _id
        firstName
        lastName
        height
        weight
        bloodGroup
        dociId
        genotype
        gender
        phoneNumber
        providerId {
          _id
          name
        }
        plan
        status
        consultations
        createdAt
        image
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const findAdmin = gql`
  ${PageInfo}
  query findAccounts($role: String, $email: String, $page: Int) {
    accounts(
      filterBy: { role: $role, email: $email }
      page: $page
      orderBy: "-createdAt"
    ) {
      data {
        _id
        role
        email
        dociId
        createdAt
        updatedAt
        role {
          _id
        }
        isActive
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const findAllergies = gql`
  query findAllergies($id: String!) {
    allergies(filterBy: { profile: $id }) {
      allergies {
        _id
        medication
        severity
        food
      }
    }
  }
`;
export const getDoctorsProfileByStatus = gql`
  ${PageInfo}
  query doctorProfiles(
    $id: String
    $firstName: String
    $lastName: String
    $gender: String
    $cadre: String
    $providerId: String
    $specialization: String
    $page: Int
    $first: Int
  ) {
    doctorProfilesByStatus(
      filterBy: { isActive: $isActive, role: "doctor" }
      first: $first
      page: $page
      providerId: $providerId
    ) {
      profile {
        _id
        firstName
        lastName
        gender
        phoneNumber
        createdAt
        updatedAt
        email
        hospital
        specialization
        dob
        cadre
        picture
        providerId {
          _id
          name
          icon
          userTypeId
          createdAt
          updatedAt
        }
        consultations
        status
        dociId
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;

export const findProfile = gql`
  query findProfile($id: String!) {
    profile(id: $id) {
      _id
      firstName
      lastName
      height
      weight
      bloodGroup
      email
      genotype
      gender
      phoneNumber
      providerId {
        _id
        name
      }
      plan
      dociId
      status
      consultations
      createdAt
      image
    }
  }
`;
export const getAMessage = gql`
  query getMessage($id: ID!) {
    getMessage(id: $id) {
      _id
      recipient
      subject
      sender
      createdAt
      updatedAt
      body
    }
  }
`;
export const getDrugOrder = gql`
  query getDrugOrder($id: String!) {
    getDrugOrder(data: { id: $id }) {
      _id
      partner {
        _id
        name
      }
      patient {
        _id
        firstName
        lastName
        gender
        image
        dociId
      }
      doctor {
        _id
        firstName
        lastName
        picture
      }
      orderId
      status
      consultationId
      note
      cancellationReason
      deliveryFee
      prescriptions {
        priceListId
        drugName
        drugPrice
        unitPrice
        dosageQuantity
        notes
      }
      userLocation {
        address
        phoneNumber
        city
        lat
        lng
      }
    }
  }
`;
export const getAppointments = gql`
  query getNextConsultations(
    $doctorId: String
    $patientId: String
    $page: Int
    $first: Int
  ) {
    getNextConsultations(
      filterBy: { doctor: $doctorId, patient: $patientId }
      page: $page
      first: $first
    ) {
      data {
        _id
        patient {
          _id
          firstName
          lastName
        }
        doctor {
          _id
          firstName
          lastName
        }
        time
        contactMedium
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        createdAt
        updatedAt
        referralId
        providerId
        status
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const cancelDrugOrder = gql`
  mutation cancelDrugOrder($id: String!, $reason: String!) {
    cancelDrugOrder(data: { id: $id, reason: $reason }) {
      drugOrder {
        _id
      }
    }
  }
`;
export const getConsult = gql`
  query getConsultation($id: ID!) {
    getConsultation(data: { id: $id }) {
      _id
      patient {
        _id
        firstName
        lastName
        image
      }
      consultationOwner
      symptoms {
        name
      }
      description
      discomfortLevel
      firstNotice
      doctor {
        _id
        firstName
        lastName
      }
      type
      contactMedium
      status
      providerId
      isDisputed
      disputeReason
      disputeStatus
      appointmentAcceptedAt
      declineReason
      appointmentStartedAt
      patientJoined
      patientSatisfied
      patientEndCommunicationReason
      reason
      rating {
        review
        score
      }
      doctorJoined
      doctorEndCommunicationReason
      disputeResolvedReason
      isDisputeResolved
      declineReason
      consultationDuration
      diagnosis {
        ailment
        severity
      }
      doctorNote
      createdAt
      updatedAt
      referralId
      prescription {
        _id
        drugs {
          priceListId
          drugName
          drugPrice
          unitPrice
          dosageQuantity
          dosageUnit
          route
          amount
          instructions
          dosageFrequency {
            timing
            duration
          }
        }
      }
    }
  }
`;
export const getConsultations = gql`
  ${PageInfo}
  query getConsultations(
    $id: ID
    $type: String
    $status: String
    $contact: String
    $orderBy: String!
    $page: Int
    $first: Int
    $startDate: String
    $endDate: String
    $providerId: String
    $disputed: Boolean
  ) {
    getConsultations(
      filterBy: {
        patient: $id
        type: $type
        status: $status
        contactMedium: $contact
        providerId: $providerId
        isDisputed: $disputed
      }
      startDate: $startDate
      endDate: $endDate
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      data {
        _id
        patient {
          _id
          firstName
          lastName
        }
        consultationOwner
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor {
          _id
          firstName
          lastName
        }
        consultationDuration
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        contactMedium
        type
        status
        createdAt
        updatedAt
        referralId
        providerId
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getDiagnosticTests = gql`
  ${PageInfo}
  query getDiagnosticTests(
    $status: String
    $page: Int
    $first: Int
    $testId: String
    $partnerProviderId: String!
  ) {
    getDiagnosticTests(
      filterBy: {
        testId: $testId
        status: $status
        partner: $partnerProviderId
      }
      orderBy: "-updatedAt"
      page: $page
      first: $first
    ) {
      data {
        _id
        partner {
          _id
          name
        }
        patient {
          _id
          firstName
          lastName
        }
        doctor {
          _id
          firstName
          lastName
          picture
        }
        referralId
        note
        sampleCollection
        testResults {
          title
          file
        }
        testPickUpFee
        cancellationReason
        testId
        total
        tests {
          paid
          price
          _id
          tat
          partner
          name
          testPriceMarkUp
          markedUpTestPrice
        }
        time
        scheduledAt
        createdAt
        updatedAt
        userLocation {
          address
          phoneNumber
          city
          lat
          lng
        }
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
// export const getDiagnosticTests = gql`
//   ${PageInfo}
//   query getDiagnosticTests(
//     $status: String
//     $page: Int
//     $first: Int
//     $testId: String
//     $partnerProviderId: String!
//   ) {
//     getDiagnosticTests(
//       filterBy: {
//         testId: $testId
//         status: "pending"
//         partner: $partnerProviderId
//       }
//       orderBy: "-createdAt"
//       page: $page
//       first: $first
//     ) {
//       data {
//         _id
//         partner
//         patient
//         doctor
//         reason
//         referralId
//         note
//         sampleCollection
//         testResults
//         cancellationReason
//         partnerData
//         scheduledAt
//         userLocation {
//           address
//           phoneNumber
//           city
//           lat
//           lng
//         }
//       }
//       pageInfo {
//         ...pageDetails
//       }
//     }
//   }
// `;
export const cancelDiagnosticReferral = gql`
  query cancelDiagnosticReferral($id: String) {
    cancelDiagnosticReferral(id: $id) {
      _id
      partner
      patient
      patientData
      doctorData
      doctor

      testId
      referralId
      note
      reason
      sampleCollection
      createdAt
      userLocation {
        address
        city
        lat
        lng
      }
    }
  }
`;
export const getDiagnosticTest = gql`
  query getDiagnosticTest($id: String!) {
    getDiagnosticTest(data: { id: $id }) {
      _id
      partner {
        _id
        name
      }
      patient {
        _id
        gender
        firstName
        lastName
      }
      doctor {
        _id
        firstName
        lastName
      }
      reason
      referralId
      note
      testId
      status
      scheduledAt
      sampleCollection
      testResults {
        title
        file
      }
      cancellationReason
      createdAt
      time
      tests {
        name
        price
        tat
        paid
      }
      userLocation {
        address
        phoneNumber
        city
        lat
        lng
      }
    }
  }
`;
export const getDOCAppoint = gql`
  query getAppointments($id: ID!, $orderBy: String!) {
    getAppointments(filterBy: { doctor: $id }, orderBy: $orderBy) {
      data {
        _id
        doctor
        patient
        date
        time
        createdAt
        updatedAt
        patientData
        doctorData
      }
    }
  }
`;

export const getDocConsult = gql`
  ${PageInfo}
  query getConsultations($id: String!, $page: Int, $first: Int) {
    getConsultations(filterBy: { doctor: $id }, page: $page, first: $first) {
      data {
        _id
        patient {
          _id
          firstName
          lastName
        }
        consultationOwner
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor {
          _id
          firstName
          lastName
        }
        type
        status
        contactMedium
        consultationDuration
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        createdAt
        updatedAt
        referralId
        providerId
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getDoctorPatients = gql`
  ${PageInfo}
  query getDoctorPatients($id: String!, $page: Int, $first: Int) {
    getDoctorPatients(filterBy: { doctor: $id }, page: $page, first: $first) {
      data {
        _id
        doctor {
          _id
          firstName
          lastName
        }
        patient {
          _id
          firstName
          lastName
          picture
        }
        createdAt
        updatedAt
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getDoctorsProfile = gql`
  ${PageInfo}
  query doctorProfiles(
    $id: String
    $firstName: String
    $lastName: String
    $gender: String
    $cadre: String
    $providerId: String
    $specialization: String
    $page: Int
    $first: Int
    $search: String
  ) {
    doctorProfiles(
      filterBy: {
        dociId: $id
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        cadre: $cadre
        providerId: $providerId
        specialization: $specialization
      }
      search: $search
      orderBy: "-createdAt"
      first: $first
      page: $page
    ) {
      profile {
        _id
        firstName
        lastName
        gender
        phoneNumber
        createdAt
        updatedAt
        email
        hospital
        specialization
        dob
        cadre
        picture
        providerId {
          _id
          name
          icon
          userTypeId
          createdAt
          updatedAt
        }
        status
        dociId
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getEarningStats = gql`
  query getEarningStats($page: Int, $providerId: String, $first: Int) {
    getEarningStats(
      filterBy: { providerId: $providerId }
      q: "365"
      page: $page
      first: $first
    ) {
      totalEarnings
      totalPayout
      earningData
      payoutData
    }
  }
`;

export const getMyEarningDoc = gql`
  query getMyEarnings($doctor: String) {
    getMyEarnings(filterBy: { doctor: $doctor }, page: 1) {
      data {
        _id
        doctor
        balance
        doctor {
          _id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;
export const getEmailList = gql`
  query getEmailList {
    getEmailList(orderBy: "-createdAt") {
      data {
        _id
        email
        createdAt
        updatedAt
        profileData
        role
        email
      }
    }
  }
`;
export const getLabResult = gql`
  query getLabResults($id: ID!) {
    getLabResults(filterBy: { patient: $id }) {
      lab {
        _id
        url
        partner
        doctor
        createdAt
        updatedAt
      }
    }
  }
`;
export const getMessage = gql`
  ${PageInfo}
  query getMessages($recipient: String, $providerId: String, $page: Int) {
    getMessages(
      filterBy: { providerId: $providerId, recipient: $recipient }
      page: $page
      orderBy: "-createdAt"
    ) {
      messages {
        _id
        recipient
        subject
        sender
        createdAt
        updatedAt
        body
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getPatients = gql`
  ${PageInfo}
  query findProfiles(
    $gender: String
    $providerId: String
    $firstName: String
    $lastName: String
    $page: Int
    $id: String
    $first: Int
    $search: String
  ) {
    profiles(
      filterBy: {
        providerId: $providerId
        gender: $gender
        dociId: $id
        firstName: $firstName
        lastName: $lastName
      }
      search: $search
      orderBy: "-createdAt"
      page: $page
      first: $first
    ) {
      data {
        _id
        firstName
        lastName
        height
        weight
        bloodGroup
        dociId
        genotype
        gender
        phoneNumber
        providerId {
          _id
          name
        }
        plan
        status
        consultations
        createdAt
        image
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;

export const getSubscriptionsIncome = gql`
  query getEarningStats($first: Int, $page: Int, $providerId: String) {
    getEarningStats(
      filterBy: { providerId: $providerId }
      q: "365"
      page: $page
      orderBy: "-createdAt"
      first: $first
    ) {
      subscriptionIncomeData
    }
  }
`;

export const getAvailabilities = gql`
  ${PageInfo}
  query getAvailabilities(
    $id: String
    $providerId: String
    $page: Int
    $day: String
    $first: Int
  ) {
    getAvailabilities(
      filterBy: { doctor: $id, providerId: $providerId, day: $day }
      page: $page
      first: $first
    ) {
      availability {
        _id
        doctor {
          _id
          firstName
          lastName
          dociId
        }
        createdAt
        updatedAt
        providerId
        day
        available
        times {
          start
          stop
          available
        }
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;

export const getMyEarnings = gql`
  ${PageInfo}
  query getMyEarnings($doctor: String, $page: Int, $first: Int) {
    getMyEarnings(
      filterBy: { doctor: $doctor }
      first: $first
      page: $page
      orderBy: "-createdAt"
    ) {
      data {
        _id
        balance
        doctor {
          _id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      totalEarnings
      totalPayouts
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getPayouts = gql`
  ${PageInfo}
  query getPayouts($page: Int, $doctor: String, $first: Int) {
    getPayouts(filterBy: { doctor: $doctor }, page: $page, first: $first) {
      data {
        _id
        doctor {
          _id
          firstName
          lastName
        }
        amount
        status
        createdAt
        updatedAt
        providerId {
          _id
          name
        }
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getPayoutData = gql`
  query getEarningStats(
    $first: Int
    $page: Int
    $status: String
    $doctor: String
  ) {
    getEarningStats(
      filterBy: { status: $status, doctor: $doctor }
      q: "365"
      page: $page
      first: $first
      orderBy: "-createdAt"
    ) {
      payoutData
    }
  }
`;

export const dashboard = gql`
  query getStats($providerId: String) {
    getStats(filterBy: { providerId: $providerId }) {
      patientStats {
        totalActive
        totalInactive
        activeChartData
        inactiveChartData
      }
      doctorStats {
        totalActive
        totalInactive
        activeChartData
        inactiveChartData
      }
      partnerStats {
        total
        chartData
        hospitalChartData
        diagnosticsChartData
        pharmacyChartData
        totalHospitals
        totalPharmacies
        totalDiagnostics
      }
      subscriptionStats {
        totalActive
        totalInactive
        chartData
        activeChartData
        inactiveChartData
      }
      earningStats {
        total
        chartData
      }
      payoutStats {
        total
        chartData
      }
      consultationStats {
        totalOngoing
        totalAccepted
        totalCompleted
        totalDeclined
        totalCancelled
        ongoingChartData
        acceptedChartData
        completedChartData
        declinedChartData
        cancelledChartData
      }
      availabilityCalender {
        today
        availableDoctors {
          dociId
          firstName
          lastName
          providerId
          availability {
            times {
              start
              stop
              available
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const dashboard1 = gql`
  query getStats {
    getStats(filterBy: {}, q: $q) {
      patientStats {
        totalActive
        totalInactive
        activeChartData
        inactiveChartData
      }
      doctorStats {
        totalActive
        totalInactive
        activeChartData
        inactiveChartData
      }
      partnerStats {
        total
        chartData
        hospitalChartData
        diagnosticsChartData
        pharmacyChartData
        totalHospitals
        totalPharmacies
        totalDiagnostics
      }
      subscriptionStats {
        totalActive
        totalInactive
        chartData
        activeChartData
        inactiveChartData
      }
      earningStats {
        total
        chartData
      }
      payoutStats {
        total
        chartData
      }
      consultationStats {
        totalOngoing
        totalAccepted
        totalCompleted
        totalDeclined
        totalCancelled
        ongoingChartData
        acceptedChartData
        completedChartData
        declinedChartData
        cancelledChartData
      }
      availabilityCalender {
        today
        availableDoctors {
          dociId
          firstName
          lastName
          providerId
          availability {
            times {
              start
              stop
              available
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

// export const getPlans = gql`
//   ${PageInfo}
//   query getPlans{
//     getPlans(
//       filterBy: {type: "hmo" }
//     ) {
//       data {
//         _id
//         name
//         amount
//         description
//         provider {
//           _id
//         }
//         duration
//         createdAt
//         updatedAt
//       }
//       pageInfo {
//         ...pageDetails
//       }
//     }
//   }
// `;

export const getPlans = gql`
  ${PageInfo}
  query getPlans(
    $type: String
    $amount: Float
    $provider: String
    $page: Int
    $first: Int
  ) {
    getPlans(
      filterBy: { amount: $amount, provider: $provider, type: $type }
      page: $page
      orderBy: "-createdAt"
      first: $first
    ) {
      data {
        _id
        name
        amount
        description
        provider {
          _id
        }
        duration
        createdAt
        updatedAt
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getProfile = gql`
  query findProfile($profileId: String!) {
    profile(id: $profileId) {
      _id
      firstName
      lastName
      height
      email
      weight
      bloodGroup
      genotype
      gender
      phoneNumber
      providerId {
        _id
        name
      }
      plan
      status
      dociId
      consultations
      createdAt
      image
    }
  }
`;
export const getRefferal = gql`
  query getReferral($id: ID!) {
    getReferral(id: $id) {
      _id
      doctor
      patient
      type
      reason
      note
      urgency
      tests {
        name
        price
        tat
      }
      specialization
      createdAt
      updatedAt
      doctorData
      patientData
    }
  }
`;
export const getRefferals = gql`
  ${PageInfo}
  query getReferrals(
    $doctor: String
    $id: String
    $page: Int
    $specialization: String
    $patient: String
    $providerId: String
    $first: Int
  ) {
    getReferrals(
      filterBy: {
        doctor: $doctor
        providerId: $providerId
        _id: $id
        specialization: $specialization
        patient: $patient
      }
      orderBy: "-createdAt"
      page: $page
      first: $first
    ) {
      referral {
        _id
        doctor {
          _id
          firstName
          lastName
        }
        patient {
          _id
          firstName
          lastName
        }
        type
        reason
        note
        specialization
        createdAt
        updatedAt
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const getRoles = gql`
  ${PageInfo}
  query getRoles($name: String, $page: Int) {
    getRoles(filterBy: { name: $name }, page: $page, orderBy: "-createdAt") {
      role {
        _id
        name
        permissions
        editable
        description
        createdAt
        updatedAt
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const myMedic = gql`
  ${PageInfo}
  query getMyMedications($id: ID!, $orderBy: String!, $page: Int) {
    getMedications(filterBy: { patient: $id }, page: $page, orderBy: $orderBy) {
      medication {
        _id
        name
        interval
        createdAt
        updatedAt
        doctor
        dosage
        patient
      }
      pageInfo {
        ...pageDetails
      }
    }
  }
`;
export const verifiedEmail = gql`
  query findAccounts($dociId: String) {
    accounts(filterBy: { dociId: $dociId }) {
      data {
        isEmailVerified
      }
    }
  }
`;
export const getDoctorByDociId = gql`
  query doctorProfiles($dociId: String!) {
    doctorProfiles(filterBy: { dociId: $dociId }) {
      profile {
        firstName
        lastName
      }
    }
  }
`;
export const getProfileByDociId = gql`
  query findProfiles($dociId: String!) {
    profiles(filterBy: { dociId: $dociId }) {
      data {
        _id
        firstName
        lastName
      }
    }
  }
`;
export const getAvailability = gql`
  query getAvailabilities($id: String!) {
    getAvailabilities(filterBy: { doctor: $id }) {
      availability {
        _id
        createdAt
        updatedAt
        day
        available
        times {
          start
          stop
        }
      }
    }
  }
`;
export const getSinglePlan = gql`
  query getPlan($id: String!) {
    getPlan(id: $id) {
      name
      amount
      description
      provider {
        _id
        name
      }
      duration
    }
  }
`;
export const getUsertypess = gql`
  query getUserTypeProviders($userTypeId: String) {
    getUserTypeProviders(filterBy: { userTypeId: $userTypeId }) {
      provider {
        _id
        name
        icon
        userTypeId {
          _id
          name
        }
        createdAt
        updatedAt
        userTypeData {
          name
          icon
          createdAt
          updatedAt
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

// export const getEnrolles = gql`
//   query getEnrolles($providerId: String ) {
//     getEnrollees(
//       filterBy: {
//         providerId: $providerId
//       }
//     ) {
//       data {
//         _id
//         firstName
//         lastName
//         email
//         hmoId
//         photo
//         noc
//         accessPlanName
//         phone
//         plan
//         planId {
//           _id
//           name
//           amount
//         }
//         expiryDate
//         status
//         companyId {
//           _id
//           name
//           logo
//         }
//         deactivated
//       }
//       pageInfo {
//         totalDocs
//         limit
//         offset
//         hasPrevPage
//         hasNextPage
//         page
//         totalPages
//         pagingCounter
//         prevPage
//         nextPage
//       }
//     }
//   }
// `;

export const getEnrolles = gql`
  query getEnrolles(
    $page: Int
    $first: Int
    $firstName: String
    $lastName: String
    $providerId: String
    $hmoId: String
    $planId: String
    $plan: String
    $companyId: String
    $search: String
  ) {
    getEnrollees(
      filterBy: {
        providerId: $providerId
        firstName: $firstName
        lastName: $lastName
        hmoId: $hmoId
        plan: $plan
        planId: $planId
        companyId: $companyId
      }
      search: $search
      first: $first
      page: $page
      orderBy: "-createdAt"
    ) {
      data {
        _id
        firstName
        lastName
        email
        hmoId
        photo
        noc
        accessPlanName
        phone
        plan
        planId {
          _id
          name
          amount
        }
        expiryDate
        status
        companyId {
          _id
          name
          logo
        }
        deactivated
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;
export const getEnrollees = gql`
  query getEnrollees($providerId: String, $hmoId: String, $page: Int) {
    getEnrollees(
      filterBy: { hmoId: $hmoId, providerId: $providerId }
      page: $page
      orderBy: "-createdAt"
    ) {
      data {
        _id
        firstName
        lastName
        email
        hmoId
        photo
        noc
        phone
        planId {
          _id
          name
          amount
        }
        expiryDate
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const hmoDashboard = gql`
  query getStats($providerId: String) {
    getStats(filterBy: { providerId: $providerId }) {
      patientStats {
        total
        totalActive
        totalInactive
        chartData
        activeChartData
        inactiveChartData
      }

      consultationStats {
        total
        totalOngoing
        totalAccepted
        totalCompleted
        totalDeclined
        totalCancelled
        chartData
        ongoingChartData
        acceptedChartData
        completedChartData
        declinedChartData
        cancelledChartData
      }

      enrolleeStats {
        total
        totalActive
        totalInactive
        chartData
        activeChartData
        inactiveChartData
      }
    }
  }
`;

export const getHmoPlans = gql`
  query getProvider($id: String!) {
    getProvider(id: $id) {
      _id
      name
      hmoPlans {
        name
        planId
      }
    }
  }
`;

export const getEnrolleeProfileId = gql`
  query findProfiles($hmoId: String, $providerId: String) {
    profiles(
      filterBy: { hmoId: $hmoId, providerId: $providerId }
      orderBy: "-createdAt"
      page: 1
    ) {
      data {
        _id
        firstName
        lastName
        providerId {
          _id
          name
        }
      }
    }
  }
`;

export const getPartnerID = gql`
  query getPartner($id: String!) {
    getPartner(data: { id: $id }) {
      _id
      name
      email
      category
      accountId
    }
  }
`;

export const getAccountPrivateInfo = gql`
  query findAccount($id: String!) {
    account(id: $id) {
      _id
      email
      dociId
      providerId
      apiKey
    }
  }
`;

export const getHMOWidgetConfiguration = gql`
  query getPartnerConfiguration($partnerId: String!) {
    getPartnerConfiguration(data: { partner: $partnerId }) {
      partner
      widgetColor
      widgetPosition
      widgetSize
      widgetTextHeader
      widgetTextColor
      widgetLogo
      widgetIcon
      subdomain
      category
    }
  }
`;

export const getCompanies = gql`
  query getCompanies(
    $search: String
    $page: Int
    $providerId: String
    $orderBy: String
  ) {
    getCompanies(
      filterBy: { providerId: $providerId }
      search: $search
      page: $page
      orderBy: $orderBy
    ) {
      data {
        _id
        name
        phone
        logo
        address
        email
        enrolleeCount
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const getCompany = gql`
  query getCompany($companyId: String!) {
    getCompany(data: { id: $companyId }) {
      _id
      name
      enrolleeCount
    }
  }
`;

export const reactivateCompanyEnrollee = gql`
  mutation reactivateCompanyEnrollee($companyId: String) {
    reactivateCompanyEnrollees(data: { companyId: $companyId }) {
      count
    }
  }
`;

export const deactivateCompanyEnrollee = gql`
  mutation deactivateCompanyEnrollee($companyId: String) {
    deactivateCompanyEnrollees(data: { companyId: $companyId }) {
      count
    }
  }
`;

export const getPrescriptions = gql`
  query getPrescriptions($patientId: String, $page: Int, $orderBy: String) {
    getPrescriptions(
      filterBy: { patient: $patientId }
      page: $page
      orderBy: $orderBy
    ) {
      data {
        _id
        doctor {
          _id
          firstName
          lastName
        }
        patient {
          _id
          firstName
          lastName
        }
        consultation
        createdAt
        updatedAt
        drugs {
          priceListId
          drugName
          drugPrice
          unitPrice
          dosageQuantity
          dosageUnit
          route
          amount
          instructions
          dosageFrequency {
            timing
            duration
          }
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const exportConsultationsQuery = gql`
  query exportConsultationsQuery(
    $providerId: String
    $startDate: String
    $endDate: String
  ) {
    exportConsultationsNew(
      filterBy: { providerId: $providerId }
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const getPrescriptionReferralsQuery = gql`
  query getPrescriptionReferrals(
    $providerId: String
    $page: Int
    $first: Int
    $search: String
  ) {
    prescriptionReferrals(
      filterBy: { providerId: $providerId }
      page: $page
      first: $first
      search: $search
      orderBy: "-createdAt"
    ) {
      data {
        _id
        createdAt
        doctor {
          lastName
          firstName
        }
        patient {
          firstName
          lastName
        }
        status
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const getPrescriptionReferral = gql`
  query getPrescriptionReferral($id: String!) {
    prescriptionReferral(data: { id: $id }) {
      _id
      createdAt
      doctor {
        _id
        lastName
        firstName
      }
      patient {
        _id
        firstName
        lastName
        hmoId
        dob
        gender
      }
      consultation {
        _id
        doctorNote
        diagnosis {
          ailment
          severity
        }
      }
      tests {
        _id
        name
        price
        approved
      }
      testsNote
      drugs {
        _id
        drugName
        approved
        dosageUnit
        dosageQuantity
        dosageFrequency {
          duration
          timing
        }
        instructions
        notes
        quantity
        amount
      }
      drugsNote
      status
    }

    drugPartners: getPartners(filterBy: { category: "pharmacy" }) {
      data {
        _id
        name
        logoImageUrl
      }
    }

    testPartners: getPartners(filterBy: { category: "diagnostics" }) {
      data {
        _id
        name
        logoImageUrl
      }
    }
  }
`;

export const testsApprovals = gql`
  query testsApprovals($partnerId: String, $page: Int, $first: Int, $search: String) {
    testApprovals(filterBy: {partner: $partnerId}, page: $page, first: $first, search: $search) {
      data {
        _id
        createdAt
        status
        doctor {
          firstName
          lastName
        }
        patient {
          firstName
          lastName
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const testApproval = gql`
  query testApproval($id: String!) {
    testApproval(data: { id: $id }) {
      _id
      createdAt
      tests {
        name
        notes
      }
      note
      status
      doctor {
        firstName
        lastName
      }
      patient {
        firstName
        lastName
      }
    }
  }
`;

export const drugsApprovals = gql`
  query drugsApprovals($partnerId: String, $page: Int, $first: Int, $search: String) {
    drugsApprovals(filterBy: {partner: $partnerId}, page: $page, first: $first, search: $search) {
      data {
        _id
        createdAt
        status
        doctor {
          firstName
          lastName
        }
        patient {
          firstName
          lastName
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const drugApproval = gql`
  query drugApproval($id: String!) {
    drugApproval(data: { id: $id }) {
      _id
      createdAt
      drugs {
        drugName
        quantity
        drugPrice
        drugForm
        dosageUnit
        dosageFrequency {
          duration
          timing
        }
      }
      note
      status
      doctor {
        firstName
        lastName
      }
      patient {
        firstName
        lastName
      }
    }
  }
`;

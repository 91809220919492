import moment from "moment";
import Papa from "papaparse";
import DateFnsAdapter from "@date-io/date-fns";
import { mockOpt } from "./mockData";
import { format } from "date-fns";
import { restApi } from "helpers/rest-api";
const dateFns = new DateFnsAdapter();

export const arrangeItems = (array) => {
  let finalArray = [];
  let n = 0;
  let arrayValues = [];
  let arrayObject = {};
  arrayObject.name = undefined;

  // array.sort();
  array.forEach((element) => {
    let container = element.split(":");
    if (arrayObject.name === container[0]) {
      arrayValues.push(container[1]);
      arrayObject.value = arrayValues;
    } else {
      finalArray[n] = arrayObject;
      arrayValues = [];
      arrayObject = {};
      arrayObject.name = container[0];
      arrayValues.push(container[1]);
      arrayObject.value = arrayValues;
      n += 1;
    }
  });
  finalArray.shift();
  return finalArray;
};

export const removeEmptyStringValues = (obj) => {
  try {
    let newObj = {};
    for (const key in obj) {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  } catch (err) {
    console.err("error from removeEmptyStringValues FN", err);
    return obj;
  }
};

export const setSideNav = (appNavData, pathname, setNav) => {
  try {
    const pathArr = pathname.split("/");
    if (pathArr.length < 2) {
      setNav(0);
      return;
    }
    // eslint-disable-next-line
    appNavData.map((data) => {
      // eslint-disable-next-line no-restricted-syntax
      if (data.path === `/${pathArr[1]}`) {
        return setNav(data?.id);
      }
    });
  } catch (error) {
    console.error("Something sent wrong with setSideNav FN", error);
  }
};

export const formatDate = (date, formatType) => {
  try {
    const parsedDate = Date.parse(date);
    const formatedDate = dateFns.format(parsedDate, formatType);
    return formatedDate;
  } catch (error) {
    console.error(error);
    return "No date";
  }
};

export const getInitials = (name) => {
  try {
    const splittedNamesArr = name.split(" ");

    const initialsArr = splittedNamesArr.map((name) => {
      const splittedNameArr = name.split("");
      return splittedNameArr[0];
    });

    return initialsArr.join("").toUpperCase();
  } catch (error) {
    console.error("error from getInitials func.", error);
    return "";
  }
};

export const dateMoment = (dateString) => {
  const formattedDate = moment(dateString).utc().format("YYYY-MM-DD");
  if (formattedDate === "Invalid date") {
    return null;
  } else {
    return formattedDate;
  }
};

export const isFile = (file, fileType) => {
  try {
    const fileArr = file?.name.split(".");
    const lastItem = fileArr[fileArr.length - 1];
    if (lastItem === fileType) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("couldn't check file type", error);
    return false;
  }
};

export const uploadFile = async (file) => {
  try {
    const form = new FormData();
    form.append("file", file);
    const data = await restApi.post("rest/media", form);
    return data.data.data.mediaUrl;
  } catch (error) {
    console.error(error);
  }
};

export const IsImg = (file) => {
  const imgFormatsRegex = new RegExp(
    /(jpeg|png|jpg|webp|jpg|jpeg|jfif|pjpeg|pjp|svg)/
  );
  try {
    const fileArr = file?.name.split(".");
    const lastItem = fileArr[fileArr.length - 1];
    if (imgFormatsRegex.test(lastItem)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("couldn't check if file is an image", error);
    return false;
  }
};

export const getDynamicSearchPlaceholder = (
  filterBy,
  obj = { hmoId: "Search by HMO ID" }
) => {
  let placeHolder = "";
  Object.keys(obj).forEach((key) => {
    if (key === filterBy) placeHolder = obj[key];
  });
  return placeHolder;
};

export const trucateString = (word, length, startFrom = "front") => {
  try {
    const wordArr = word.split("");
    if (wordArr.length <= length) {
      return word;
    }
    if (startFrom === "front") {
      const newWord = `${wordArr.slice(0, length).join("")}...`;
      return newWord;
    }

    if (startFrom === "back") {
      const newWord = `...${wordArr
        .slice(wordArr.length - length, wordArr.length - 1)
        .join("")}`;
      return newWord;
    }
  } catch (error) {
    console.error("Error from trucateString FN", error);
    return word;
  }
};

export const selectNum = (num) => {
  try {
    const remaining = `${num / 4}`.split(".")[1];

    switch (remaining) {
      case undefined:
        return 3;

      case "5":
        return 1;

      case "75":
        return 2;

      case "25":
        return 0;

      default:
        return 0;
    }
  } catch (error) {
    console.error("Error from selectNum func.", error);
    return 1;
  }
};

export const pickHmoPlans = (planArray, names) => {
  try {
    const pickedPlans = planArray.map((plan) => {
      const valuesArr = names.map((name) => {
        return { [name]: plan[name] };
      });
      let combinedObj = {};
      for (let index = 0; index < valuesArr.length; index++) {
        combinedObj = { ...combinedObj, ...valuesArr[index] };
      }
      return combinedObj;
    });

    return pickedPlans;
  } catch (error) {
    console.error("Error from pickHmoPlans function", error);
    return planArray;
  }
};

export const changeGraphTotal = (stats, type, option) => {
  try {
    return stats?.[mockOpt[type]?.[option]];
  } catch (error) {
    console.error("Error from changeGraphTotal FN", error);
    return 0;
  }
};

export const testColorCode = (value) => {
  const hexRegex = new RegExp(/^#([0-9a-f]{3}){1,2}$/i);
  const rgbaRegex = new RegExp(
    /rgba?\(((25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,\s*?){2}(25[0-5]|2[0-4]\d|1\d{1,2}|\d\d?)\s*,?\s*([01]\.?\d*?)?\)/
  );
  const isHex = hexRegex.test(value);
  const isRgba = rgbaRegex.test(value);
  return isHex || isRgba;
};

export const generateConsultationWidgetScript = (data, cdnBaseUrl, type) => {
  if (!cdnBaseUrl) return;
  return `
  <script>
    var Heala_config = {
      providerId: "${data?.providerId}",
      apiKey: "${data?.apiKey}",
      type: "${type}",
      floatButtonText: "${data?.text}",
      floatButtonTextColor: "color: ${data?.textColor}",
      widgetColor: "${data?.widgetColor}",
      floatButtonImg: "${data?.logomarkUrl}",
      floatButtonSize: "${data?.widgetSize}",
      floatButtonPosition: "${data?.widgetPosition}",
      formLogoUrl: "${data?.wordmarkUrl}",
      formContainerStyles: null,
      formSubmitButtonStyles: null,
      formInputsStyles: null,
      floatButtonStyles: null,
    };
  </script>
  <script id="heala-loader" type="module" src="https://${cdnBaseUrl}widget.js" defer></script>
  `;
};

export const replaceSpaceWithDash = (string) => string.split(" ").join("-");

export const hasScriptBeenGenerated = (initialValues) => {
  const isGenerated = [
    "providerId",
    "apiKey",
    "widgetSize",
    "widgetPosition",
    "widgetColor",
    "text",
    "textColor",
    "wordmarkUrl",
  ].every((field) => initialValues[field] && initialValues[field] !== "");
  return isGenerated;
};

export const onhandleSingleSelectClick = (
  id,
  selectedRows,
  setSelectedRows
) => {
  const selectedIndex = selectedRows.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selectedRows, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selectedRows.slice(1));
  } else if (selectedIndex === selectedRows.length - 1) {
    newSelected = newSelected.concat(selectedRows.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selectedRows.slice(0, selectedIndex),
      selectedRows.slice(selectedIndex + 1)
    );
  }

  setSelectedRows(newSelected);
};

export const getTodaysStartEndDate = () => {
  const now = new Date();
  const todaysDate = format(new Date(), "yyyy-MM-dd");
  const tomorrowsDate = format(now.setDate(now.getDate() + 1), "yyyy-MM-dd");

  return {
    startDate: todaysDate,
    endDate: tomorrowsDate,
  };
};

export const getYesterdaysStartEndDate = () => {
  const now = new Date();
  const yesterdaysDate = format(
    new Date().setDate(now.getDate() - 1),
    "yyyy-MM-dd"
  );
  const todaysDate = format(new Date(), "yyyy-MM-dd");

  return {
    startDate: yesterdaysDate,
    endDate: todaysDate,
  };
};

export const calculateFilterDate = (type) => {
  switch (type) {
    case "today":
      return getTodaysStartEndDate();

    case "yesterday":
      return getYesterdaysStartEndDate();

    default:
      break;
  }
};

export const checkUploadHeaders = (file, headers = []) => {
  return new Promise((res, rej) => {
    Papa.parse(file, {
      complete: (result) => {
        try {
          const csvHeaders = result?.data[0];
          console.log("headers from checkUploadHeaders func:", csvHeaders);
          const isValid = headers.every(
            (header, index) =>
              `${header}`.toLowerCase() ===
              `${csvHeaders[index]}`.toLocaleLowerCase()
          );
          res(isValid);
        } catch (err) {
          rej(err);
        }
      },
    });
  });
};

export const formatSecs = (secs) => {
  try {
    const time = Number(secs);
    const mins = Math.floor(time / 60);
    const seconds = time - mins * 60;
    return `${mins}m ${seconds}s`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return "0s";
  }
};

export function clearCacheStorageAndCookies() {
  // Clear cache
  caches.keys().then(function (names) {
    for (const name of names) {
      caches.delete(name);
    }
  });

  // Clear cookies
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }

  // window.location.reload();
}

export const isAppLoading = (isAuthenticated, role, state, loading) => {
  const roles = ["pharmacy", "diagnostics", "hmo", "hospital"];
  if (!isAuthenticated || !roles.includes(role) || !state || loading)
    return false;

  return true;
};

/**
 * Calculate age from date of birth
 * @param {string} dateOfBirth - The date of birth in serialized date format
 * @returns {number} - The calculated age
 */
export const calculateAge = (dateOfBirth) => {
  const birthDate = new Date(dateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust age if the birthday hasn't occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

